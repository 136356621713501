
import { defineComponent, PropType } from "vue";
import AppToggle from "@/components/common/AppToggle.vue";
import AutomationModel from "@/models/AutomationModel";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
    name: "AutomationsListItem",
    components: {
        AppToggle
    },
    props: {
        automation: {
            type: Object as PropType<AutomationModel>,
            required: true
        }
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();

        const changeStatus = async (val: boolean) => {
            if (val) {
                await router.push({
                    name: "automation-activation",
                    params: { id: props.automation.id }
                });
            } else {
                await store.dispatch("automations/activate", {
                    id: props.automation.id,
                    data: {
                        status: "disabled"
                    }
                });

                window.analytics.track("Automation Disabled", {
                    userId: store.getters["user/emailHash"],
                    automationType: props.automation.type
                });

                store.commit("automations/CLEAR_ACTIVE");
            }
        };
        return {
            changeStatus
        };
    }
});
