
import { computed, defineComponent, watch } from "vue";
import AppSection from "@/components/common/AppSection.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import CardKpi from "@/components/card/CardKpi.vue";
import CardChart from "@/components/card/CardChart.vue";
import CardGroup from "@/components/card/CardGroup.vue";
import CardAction from "@/components/card/CardAction.vue";
import DropdownMenu from "@/components/common/dropdown/DropdownMenu.vue";
import DropdownMenuItem from "@/components/common/dropdown/DropdownMenuItem.vue";
import MailsListItem from "@/components/mails/MailsListItem.vue";
import useKPIs from "@/composables/useKPIs";
import { useStore } from "vuex";
import store from "@/store";
import { useRouter } from "vue-router";
import AutomationsList from "@/components/automations/AutomationsList.vue";
import { useI18n } from "vue-i18n";
import goToSupport from "@/composables/goToSupport";

export default defineComponent({
    name: "DashboardPage",
    components: {
        AppIcon,
        AppButton,
        AppSection,
        CardKpi,
        CardChart,
        CardGroup,
        CardAction,
        AutomationsList,
        DropdownMenu,
        DropdownMenuItem,
        MailsListItem
    },
    async beforeRouteEnter(to, from, next) {
        // TODO: consider one endpoint ???
        const initRequests = [];

        if (store.state.dashboard.is_fetched) {
            store.dispatch("dashboard/fetchState").finally();
        } else {
            initRequests.push(store.dispatch("dashboard/fetchState"));
        }

        if (store.state.revenue.is_fetched) {
            store.dispatch("revenue/fetchGraphs").finally();
        } else {
            initRequests.push(store.dispatch("revenue/fetchGraphs"));
        }

        if (store.state.automations.total > 0) {
            store.dispatch("automations/fetchList").finally();
        } else {
            initRequests.push(store.dispatch("automations/fetchList"));
        }

        if (store.state.mails.total > 0) {
            store.dispatch("mails/fetchList").finally();
        } else {
            initRequests.push(store.dispatch("mails/fetchList"));
        }

        if (initRequests.length > 0) {
            store.commit("app/SET_LOADING", true);
            await Promise.allSettled(initRequests);
            store.commit("app/SET_LOADING", false);
        }

        next();
    },
    setup() {
        const store = useStore(),
            router = useRouter(),
            { kpiDays, kpis, kpisLoading } = useKPIs(),
            { t } = useI18n(),
            { goToSupportPage } = goToSupport();

        const chart1Dataset = computed(() => [
            {
                type: "bar",
                label: t("pages.dashboard.conversionCount"),
                borderColor: "rgba(0, 219, 173, 1)",
                backgroundColor: "rgba(0, 219, 173, 1)",
                fill: false,
                data: store.state.revenue.conversion,
                yAxisID: "left",
                order: 2
            },
            {
                label: t("pages.dashboard.maatooRevenue"),
                borderColor: "rgba(0, 193, 219, 1)",
                backgroundColor: "rgba(0, 193, 219, 1)",
                fill: false,
                data: store.state.revenue.net,
                yAxisID: "right",
                order: 1
            }
        ]);

        const chart2Dataset = computed(() => [
            {
                label: t("pages.dashboard.automationRevenue"),
                borderColor: "rgba(0, 193, 219, 1)",
                backgroundColor: "rgba(0, 193, 219, 1)",
                fill: false,
                data: store.state.revenue.automation,
                yAxisID: "right",
                order: 2
            },
            {
                type: "bar",
                label: t("pages.dashboard.conversionCount"),
                borderColor: "rgba(0, 219, 173, 1)",
                backgroundColor: "rgba(0, 219, 173, 1)",
                fill: false,
                data: [],
                yAxisID: "left",
                order: 2
            }
        ]);

        const bestSellingProduct = computed(
                () => store.state.dashboard.bestSellingProduct
            ),
            bestSellingProductOrderCount = computed(
                () => store.state.dashboard.bestSellingProductOrderCount
            ),
            bestSellingProductOrderQuantity = computed(
                () => store.state.dashboard.bestSellingProductOrderQuantity
            ),
            highestValueSegment = computed(
                () => store.state.dashboard.highestValueSegment
            ),
            store_connected = computed(
                () => store.state.dashboard.store_connected
            ),
            brand_customized = computed(
                () => store.state.dashboard.brand_customized
            ),
            legal_updated = computed(() => store.state.dashboard.legal_updated);

        const currency = computed(() => store.state.store.currency);
        const revenueChartsLoading = computed(
            () => store.state.revenue.loading
        );

        const bestSellingProductRevenue = computed(() => {
            if (bestSellingProduct.value) {
                return (
                    bestSellingProduct.value.price *
                    bestSellingProductOrderQuantity.value
                );
            }
            return 0;
        });

        const goToMails = () => {
                router.push({ name: "mails-list" });
            },
            goToAutomations = () => {
                router.push({ name: "automations-overview" });
            };

        watch(kpiDays, (val, oldVal) => {
            if (val !== oldVal) {
                store
                    .dispatch("revenue/fetchGraphs", { period: val })
                    .finally();
            }
        });

        const productFallbackImage = async (e: Event) => {
            if (e.target as HTMLImageElement) {
                const target = e.target as HTMLImageElement;
                target.src = (
                    await import("@/assets/img/placeholder.svg")
                ).default;
            }
        };

        const openSettings = () => {
            router.push({
                name: "settings-brand"
            });
        };

        const formatNumber = (value: number, maximumFractionDigits = 1) => {
            return Intl.NumberFormat("en-US", {
                notation: "compact",
                maximumFractionDigits: maximumFractionDigits
            }).format(value);
        };

        return {
            kpiDays,
            kpis,
            kpisLoading,
            revenueChartsLoading,
            chart1Dataset,
            chart2Dataset,
            activeMails: computed(() => store.getters["mails/activeList"]),
            scheduledMails: computed(
                () => store.getters["mails/scheduledList"]
            ),
            bestSellingProduct,
            bestSellingProductOrderCount,
            bestSellingProductOrderQuantity,
            bestSellingProductRevenue,
            highestValueSegment,
            store_connected,
            brand_customized,
            legal_updated,
            goToMails,
            goToAutomations,
            currency,
            goToSupportPage,
            productFallbackImage,
            openSettings,
            formatNumber
        };
    }
});
