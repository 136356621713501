
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { Line } from "vue-chartjs";
import { Chart, registerables, ScriptableContext } from "chart.js";
import "chartjs-adapter-moment";
import AppIcon from "@/components/common/AppIcon.vue";

Chart.register(...registerables);

export default defineComponent({
    name: "CardChart",
    props: {
        header: { type: String, required: true },
        datasets: { type: Array, required: true },
        disabled: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    components: {
        Line,
        AppIcon
    },
    setup(props) {
        const store = useStore();
        const currency = computed(() => store.state.store.currency);

        const chartData = computed(() => ({
            datasets: props.datasets
        }));

        const options = {
            animation: {
                x: {
                    duration: 0
                }
            },
            interaction: {
                mode: "nearest",
                intersect: false,
                axis: "x"
            },
            locale: localStorage.getItem("editorLocale"),
            maintainAspectRatio: false,
            scales: {
                left: {
                    beginAtZero: true,
                    grid: {
                        drawOnChartArea: false
                    },
                    position: "left"
                },
                right: {
                    beginAtZero: true,
                    ticks: {
                        callback: function(value: number) {
                            return `${currency.value} ${value}`;
                        }
                    },
                    grid: {
                        drawOnChartArea: false
                    },
                    position: "right"
                },
                x: {
                    type: "time",
                    time: {
                        unit: "day",
                        displayFormats: {
                            day: "MMM DD"
                        }
                    },
                    ticks: {
                        minRotation: 45,
                        autoSkip: true,
                        autoSkipPadding: 5,
                        source: "auto"
                    }
                }
            },
            tension: 0.3,
            plugins: {
                tooltip: {
                    backgroundColor: "rgba(255, 255, 255)",
                    titleColor: "#000",
                    borderColor: "#DEE2ED",
                    borderWidth: 1,
                    padding: 10,
                    callbacks: {
                        label: function(context: ScriptableContext<"line">) {
                            let label = context.dataset.label || "";

                            if (label) {
                                label += ": ";
                            }
                            if (context.parsed.y !== null) {
                                if (context.dataset.yAxisID === "right") {
                                    label += new Intl.NumberFormat(
                                        localStorage.getItem("editorLocale") ??
                                            "de-CH",
                                        {
                                            style: "currency",
                                            currency: currency.value
                                        }
                                    ).format(context.parsed.y);
                                } else {
                                    label += context.parsed.y;
                                }
                            }
                            return label;
                        },
                        labelTextColor: function() {
                            return "#000";
                        }
                    }
                }
            }
        };

        const styles = {
            height: "90%",
            position: "relative"
        };

        return {
            chartData,
            options,
            styles
        };
    }
});
