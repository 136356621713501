import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-start items-center mb-2" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_toggle = _resolveComponent("app-toggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_toggle, {
      active: _ctx.automation.status === 'enabled',
      "onUpdate:active": _ctx.changeStatus
    }, null, 8, ["active", "onUpdate:active"]),
    _createElementVNode("span", {
      innerHTML: _ctx.automation.icon,
      class: "automation-standard-icon-wrapper flex justify-center items-center ml-3 mr-2"
    }, null, 8, _hoisted_2),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.automation.name), 1)
  ]))
}