
import { computed, defineComponent } from "vue";
import AppButton from "@/components/common/AppButton.vue";
import AppIcon from "@/components/common/AppIcon.vue";

export default defineComponent({
    name: "CardAction",
    emits: ["click"],
    props: {
        header: { type: String, required: true },
        disabled: { type: Boolean, default: false },
        buttonName: { type: String, required: true },
        actionDisabled: { type: Boolean, default: false }
    },
    components: {
        AppButton,
        AppIcon
    },
    setup(props, { slots }) {
        const hasRevenue = computed(() => slots.revenue !== undefined);
        return {
            hasRevenue
        };
    }
});
