
import { computed, defineComponent, onMounted, ref } from "vue";
import AppSection from "@/components/common/AppSection.vue";
import CardGroup from "@/components/card/CardGroup.vue";
import CardRecommendedAction from "@/components/card/CardRecommendedAction.vue";
import Carousel from "primevue/carousel";
import { useStore } from "vuex";
import RecommendationModel from "@/models/RecommendationModel";
import AppLoader from "@/components/common/AppLoader.vue";

export default defineComponent({
    name: "RecommendedActionsSection",
    components: {
        AppSection,
        CardGroup,
        CardRecommendedAction,
        Carousel,
        AppLoader
    },
    setup() {
        const store = useStore();
        const loading = ref(true);

        onMounted(() => {
            store.dispatch("recommendations/fetchList").finally(() => {
                loading.value = false;
            });
        });

        const responsiveOptions = ref([
            {
                breakpoint: "1024px",
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: "600px",
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: "480px",
                numVisible: 1,
                numScroll: 1
            }
        ]);

        return {
            recommendations: computed(
                (): RecommendationModel[] =>
                    store.getters["recommendations/sortedList"]
            ),
            responsiveOptions,
            markAsRead: (id: number) => {
                store.dispatch("recommendations/markAsRead", id).finally();
            },
            loading
        };
    }
});
