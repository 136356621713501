import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_Line = _resolveComponent("Line")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bg-white rounded-xl p-5 shadow-lg hover:shadow-xl border border-white hover:border-light-3", [_ctx.disabled ? 'opacity-35' : '']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.header), 1),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 0,
            class: "animate-spin ml-2 h-3 w-3",
            name: "Spinner"
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Line, {
      "chart-data": _ctx.chartData,
      width: 300,
      height: 100,
      "chart-options": _ctx.options,
      styles: _ctx.styles
    }, null, 8, ["chart-data", "chart-options", "styles"])
  ], 2))
}