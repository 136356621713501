import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_automations_list_item = _resolveComponent("automations-list-item")!
  const _component_card_action = _resolveComponent("card-action")!

  return (_openBlock(), _createBlock(_component_card_action, {
    header: _ctx.$t('pages.dashboard.automations'),
    "button-name": _ctx.$t('pages.dashboard.viewAllAutomations'),
    onClick: _ctx.goToAutomations
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enabledAutomations, (automation) => {
        return (_openBlock(), _createBlock(_component_automations_list_item, {
          key: automation.id,
          automation: automation
        }, null, 8, ["automation"]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.disabledAutomations, (automation) => {
        return (_openBlock(), _createBlock(_component_automations_list_item, {
          key: automation.id,
          automation: automation
        }, null, 8, ["automation"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["header", "button-name", "onClick"]))
}