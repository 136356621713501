import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex h-100 items-center text-center"
}
const _hoisted_2 = { class: "w-full text-center" }
const _hoisted_3 = { class: "h-100 flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_recommended_action = _resolveComponent("card-recommended-action")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_card_group = _resolveComponent("card-group")!
  const _component_app_loader = _resolveComponent("app-loader")!
  const _component_app_section = _resolveComponent("app-section")!

  return (_openBlock(), _createBlock(_component_app_section, { class: "mb-10" }, {
    default: _withCtx(() => [
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_card_group, {
            key: 0,
            title: _ctx.$t('pages.dashboard.recommendedActions'),
            class: "mt-10 relative"
          }, {
            default: _withCtx(() => [
              (_ctx.recommendations.length)
                ? (_openBlock(), _createBlock(_component_Carousel, {
                    key: 0,
                    value: _ctx.recommendations,
                    numVisible: 3,
                    numScroll: 3,
                    responsiveOptions: _ctx.responsiveOptions
                  }, {
                    item: _withCtx((slotProps) => [
                      _createVNode(_component_card_recommended_action, {
                        onClick: ($event: any) => (_ctx.markAsRead(slotProps.data.id)),
                        class: "mx-2",
                        recommendation: slotProps.data
                      }, null, 8, ["onClick", "recommendation"])
                    ]),
                    _: 1
                  }, 8, ["value", "responsiveOptions"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("pages.dashboard.emptyRecommendations")), 1)
                  ]))
            ]),
            _: 1
          }, 8, ["title"]))
        : (_openBlock(), _createBlock(_component_card_group, {
            key: 1,
            title: _ctx.$t('pages.dashboard.recommendedActions'),
            class: "mt-10 relative"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_app_loader)
              ])
            ]),
            _: 1
          }, 8, ["title"]))
    ]),
    _: 1
  }))
}