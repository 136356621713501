
import { computed, defineComponent } from "vue";
import CardAction from "@/components/card/CardAction.vue";
import AutomationsListItem from "@/components/automations/AutomationsListItem.vue";
import MailModel from "@/models/MailModel";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "AutomationsList",
    components: {
        CardAction,
        AutomationsListItem
    },
    emits: ["click"],
    setup() {
        const store = useStore(),
            router = useRouter(),
            enabledAutomations = computed(
                (): MailModel => store.getters["automations/enabledList"]
            ),
            disabledAutomations = computed(
                (): MailModel => store.getters["automations/disabledList"]
            );

        const goToAutomations = () => {
            router.push({ name: "automations-overview" });
        };

        return {
            enabledAutomations,
            disabledAutomations,
            goToAutomations
        };
    }
});
