
import { computed, defineComponent, PropType } from "vue";
import AppIcon from "@/components/common/AppIcon.vue";
import RecommendationModel from "@/models/RecommendationModel";

export default defineComponent({
    name: "CardRecommendedAction",
    emits: ["click"],
    props: {
        recommendation: {
            type: Object as PropType<RecommendationModel>,
            required: true
        }
    },
    components: {
        AppIcon
    },
    setup(props, { emit }) {
        const route = computed(() => {
            if (props.recommendation.actionRoutePath != "") {
                return {
                    path: props.recommendation.actionRoutePath,
                    query: props.recommendation.actionRouteQueryParams
                };
            }
            return null;
        });

        return {
            emitClick: () => {
                if (props.recommendation.isPublished) {
                    emit("click");
                }
            },
            route
        };
    }
});
