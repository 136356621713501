import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "font-medium" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { class: "flex justify-between items-center" }
const _hoisted_4 = { class: "flex justify-start items-center" }
const _hoisted_5 = {
  key: 0,
  class: "rounded-full bg-light-2 w-10 h-10 flex justify-center items-center mr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col justify-start items-stretch bg-white rounded-xl p-5 shadow-lg hover:shadow-xl border border-white hover:border-light-3", [_ctx.disabled ? 'opacity-35' : '']])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.header), 1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.hasRevenue)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_app_icon, { name: "CurrencyDollar" })
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "revenue")
      ]),
      (!_ctx.actionDisabled)
        ? (_openBlock(), _createBlock(_component_app_button, {
            key: 0,
            "button-style": "secondary",
            disabled: _ctx.actionDisabled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonName), 1)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}