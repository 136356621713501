import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute top-5 right-5" }
const _hoisted_2 = { class: "font-medium" }
const _hoisted_3 = { class: "flex justify-center items-center mt-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex-1 flex items-center justify-start text-sm text-center text-dark-3" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "bg-light-1 rounded-xl border border-light-1 flex justify-between items-center px-2 py-2.5 mt-6" }
const _hoisted_8 = { class: "flex justify-start items-center h-10 text-sm" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = ["href", "target"]
const _hoisted_11 = {
  key: 2,
  class: "mt-4 bg-blue-500 text-white text-sm text-center leading-8 font-medium border border-blue-500 focus:outline-none px-5 rounded-lg cursor-default"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative flex flex-col justify-start items-stretch bg-white rounded-xl p-5 border border-white hover:border-light-3 h-100", [_ctx.recommendation.isPublished ? '' : 'opacity-40']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitClick && _ctx.emitClick(...args)))
      }, [
        _withDirectives(_createVNode(_component_app_icon, {
          name: "CheckCircle",
          class: _normalizeClass([
                        _ctx.recommendation.isPublished
                            ? 'text-light-3 cursor-pointer'
                            : 'text-green-400'
                    ]),
          size: "8"
        }, null, 8, ["class"]), [
          [
            _directive_tooltip,
            
                        _ctx.recommendation.isPublished
                            ? _ctx.$t('pages.dashboard.markAsRead')
                            : null
                    ,
            void 0,
            { right: true }
          ]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.recommendation.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: _ctx.recommendation.imageUrl,
        alt: "image",
        class: "h-20"
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        innerHTML: _ctx.recommendation.description
      }, null, 8, _hoisted_6)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_app_icon, {
          name: "CheckCircle",
          class: "text-green-400 mr-1",
          size: "5"
        }),
        _createElementVNode("span", null, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("pages.dashboard.recommendation")) + ":", 1),
          _createTextVNode(" " + _toDisplayString(_ctx.recommendation.recommendation), 1)
        ])
      ])
    ]),
    (
                _ctx.recommendation.isPublished && _ctx.recommendation.actionExternalUrl
            )
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: _ctx.recommendation.actionExternalUrl,
          target: _ctx.recommendation.actionExternalTarget,
          class: "mt-4 bg-blue-500 text-white text-sm text-center leading-8 font-medium border border-blue-500 hover:bg-blue-700 hover:border-blue-700 focus:outline-none px-5 rounded-lg"
        }, _toDisplayString(_ctx.recommendation.actionTitle), 9, _hoisted_10))
      : (_ctx.recommendation.isPublished)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: _ctx.route,
            class: "mt-4 bg-blue-500 text-white text-sm text-center leading-8 font-medium border border-blue-500 hover:bg-blue-700 hover:border-blue-700 focus:outline-none px-5 rounded-lg"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.recommendation.actionTitle), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.recommendation.actionTitle), 1))
  ], 2))
}